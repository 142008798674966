<script>
import adminProfileStructure from '@components/adminProfileStructure'
import moment from "moment";
import {mapState} from "vuex";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";

export default {
  page: {
      title: 'User Reports - Admin',
  },
  components: {PaginationRow, adminProfileStructure},
  mixins: [PaginatedDataMixin],
  computed:{
      ...mapState({
        user: state => state.auth.currentUser
      })
  },
  data() {
    return {
      fields: [
        {key: 'subject', label: 'Θέμα'},
        {key: 'description', label: 'Περιγραφή'},
        {key: 'user', label: 'Χρήστης'},
        {key: 'createdAt', label: 'Τελευταία τροποίηση', formatter: value => {
            return moment(value).format('DD/MM/YYYY H:m')}
        }
      ],
    }
  },
  created: function(){
    this.api.url = "/general/user-reports";
      this.getData();
  },
}
</script>
<template>
<adminProfileStructure :user="user">
  <template v-slot:admin-structure-content>

     <b-card-title>
          Αναφορές χρηστών
     </b-card-title>

    <div v-if="api.rows.length > 0" class="table-responsive table-borderless table-striped mb-0">
        <b-table :items="api.rows" :fields="fields" class="table table-hover" responsive="sm" :current-page="api.page">
            <template v-slot:cell(img)="row" class="text-center">
              <i :class="row.item.icon" class="text-center font-17"></i>
            </template>
            <template v-slot:cell(description)="row" class="text-center">
              <p style="min-width:300px">{{row.item.description}}</p>
            </template>
            <template v-slot:cell(user)="row" class="text-center ">

              <div class="font-weight-bold text-primary">
                {{row.item.user.firstName}}   {{row.item.user.lastName}}<br>
                <span class="text-primary-muted">
                {{row.item.user.email}}
                </span>
              </div>

            </template>
        </b-table>
    </div>

    <pagination-row :api="api" @fetchData="getData"></pagination-row>

  </template>

</adminProfileStructure>
</template>
